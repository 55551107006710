import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import taquitoHola from "../Assets/taquitoHola-blanco.png";
import taquitoError from "../Assets/error-blanco.png";
import "../styles/LoginGPT.scss";

export default function LogIn() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [email, setEmail] = useState('');
  const [waiting, setWaiting] = useState(false);
  const isError = new URLSearchParams(search).get("error");
  const isLogin = pathname.toLowerCase() === "/login";

  const handleEmailChange = (e) => setEmail(e.target.value.replace(/\s/g, '').toLowerCase());

  const google = () => window.open(`${process.env.REACT_APP_BACKURL}/google`, "_self");

  const handleEnter = (e) => e.key === "Enter" && magicLink(e);
  
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const magicLink = async (e) => {
    e.preventDefault();
    try {
      setWaiting(true);
      const res = await fetch(`${process.env.REACT_APP_BACKURL}/auth/magiclink`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      
      const data = await res.json();
      setWaiting(false)
      
      if (data?.status === 200) {
        navigate(`/check-your-inbox`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.title = isLogin ? "Iniciar sesión | Un taquito" : "Crear cuenta | Un taquito";
  }, [pathname]);

  const ErrorMessage = () => (
    <>
      <Link to="/">
        <img src={taquitoError} />
      </Link>
      <div>
        <h1>Lo sentimos</h1>
        <p>Por favor, <span>¡Vuelve a intentarlo!</span></p>
      </div>
    </>
  );

  const WelcomeMessage = () => (
    <>
      <Link to="/">
        <img src={taquitoHola} />
      </Link>
      <div>
        <h1>¡Bienvenido!</h1>
        <p>
          Inicia sesión, cuenta tu historia{" "}
          <span>¡Y prepárate para recibir contribuciónes!</span>
        </p>
      </div>
    </>
  );

  return (
    <div className="login-comp">
      <div className="login-modal">
        <section className="blue-section">
          {isError ? <ErrorMessage /> : <WelcomeMessage />}
        </section>
        <section className="grey-section">
          <div className="login-div">
            {isLogin ? (
              <>
                <h1>Inicio de sesión</h1>
                <span className="login-label">
                  <p>¿No tienes una cuenta?</p>
                  <Link to="/SignUp">
                    <p>Crea tu cuenta</p>
                  </Link>
                </span>
              </>
            ) : (
              <h1>Crea tu cuenta</h1>
            )}

            <span className="login-label">
              <p>¡Te tomará menos de 1 minuto!</p>
            </span>

            <input type="text" placeholder="Correo Electronico" onChange={handleEmailChange} onKeyDown={handleEnter}  value={email}></input>
            <button disabled={!validateEmail(email) || waiting}  className='magic-link-login' onClick={magicLink}>Iniciar sesión</button>
            {(waiting) ? <Box className='linear-ctn'><LinearProgress /></Box> : null}
          </div>

          <span className="separation-line"></span>
          <div className="buttons-div">
            <span>
              <button
                onClick={google}
                type="button"
                className="login-with-google-btn"
              >
                Acceder con Google
              </button>
            </span>

            {isError ? (
              <div className="error">
                <h6>Hubo un error!</h6>
              </div>
            ) : null}
          </div>
        </section>
      </div>
    </div>
  );
}
