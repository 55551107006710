
import { useEffect, useState, useContext } from 'react';
import Apoya from '../../Assets/contribui-azul.png';
import saltarin from '../../Assets/saltarin-azul2.png';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { PaymentContext } from '../../Context/PaymentContext';
import Box from '@mui/material/Box';
import "../../styles/Supporter.scss"
// const precio = 30;

export default function Supporter({ id, precio }) {

    const { MercadoPagoPreferences, paymentInfo } = useContext(PaymentContext)
    const [support, setSupport] = useState(1)
    const [fee, setFee] = useState((precio * 0.0349 + 4) * 1.16)
    const [nombre, setNombre] = useState(null)
    const [contacto, setContacto] = useState(null)
    const [mensaje, setMensaje] = useState(undefined)
    const [loading, setLoading] = useState(false)

    const handleOnChange = (e) => {
        if (e.target.value > 0) {
            setSupport(e.target.value)
        }
    }
    const handlePlus = () => {
        setSupport(support + 1)

    }
    const handleMin = () => {
        if (support > 1) {
            setSupport(support - 1);
        }
    }
    useEffect(() => {
        setFee(Math.round(((precio * (support)) * (0.0349 * 1.16)) * 10) / 10 + (4 * 1.16))
    }, [support, precio]);

    useEffect(() => {
    }, [paymentInfo]);

    const handlePreferences = (e) => {


        const info = {
            id: id,
            qty: support,
            msg: mensaje,
            name: nombre,
            contact: contacto
        }

        const body = JSON.stringify(info)
        MercadoPagoPreferences(body)
        setLoading(true)
    }



    return (
        <div id="supporter-grid" >

            <div id='first-row'>

                <div id="img-button-ctn">

                    <img src={Apoya} alt="taco Saltarin" />

                    <span className='mas-menos-btn-ctn'>
                        <span className="mas-menos-btn">
                            <button onClick={handleMin}>-</button>
                            <input type="number" min="0" step="1" value={support} onChange={(e) => handleOnChange(e)} />
                            <button onClick={handlePlus}>+</button>
                        </span>
                    </span>
                </div>
            </div>
            <div id='saltarin-count'>
                <span>Valor del taquito: ${precio} MXN</span>
                <ul>
                    <li><img alt='Taquito que representa donaciones por 1 taquito' className='saltarin' src={saltarin} onClick={() => { setSupport(1) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 2 taquitos' className={(support >= 2 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(2) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 3 taquitos' className={(support >= 3 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(3) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 4 taquitos' className={(support >= 4 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(4) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 5 taquitos' className={(support >= 5 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(5) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 6 taquitos' className={(support >= 6 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(6) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 7 taquitos' className={(support >= 7 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(7) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 8 taquitos' className={(support >= 8 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(8) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 9 taquitos' className={(support >= 9 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(9) }} /></li>
                    <li><img alt='Taquito que representa donaciones por 10 taquitos' className={(support >= 10 ? 'saltarin' : 'saltarin-disabled')} src={saltarin} onClick={() => { setSupport(10) }} /></li>
                </ul>
            </div>
            <div id="inputs-ctn">
                {(!loading) ? <>

                    <TextField
                        id="filled-multiline-flexible"
                        label="Nombre - Opcional"

                        maxRows={1}
                        // maxLength={2}
                        placeholder="Nombre - Opcional"
                        // variant="filled"
                        className='inputs-supporter'
                        defaultValue={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                    />
                    <TextField
                        id="filled-multiline-flexible"
                        label="Contacto - Opcional"
                        placeholder="Contacto - (no se mostrará al público))"
                        maxRows={1}
                        // maxLength={2}
                        // variant="filled"
                        className='inputs-supporter'
                        defaultValue={contacto}
                        onChange={(e) => setContacto(e.target.value)}
                    />
                    <TextField
                        id="filled-multiline-static"

                        label="Mensaje - Opcional"
                        placeholder="Mensaje - Opcional"
                        multiline
                        maxLength={70}
                        rows={2}
                        // defaultValue="Default Value"
                        // variant="filled"
                        className='inputs-supporter'
                        defaultValue={mensaje}
                        onChange={(e) => setMensaje(e.target.value)}
                        value={mensaje}
                    />  </>
                    :
                    <Box id='circular-progress'>
                        <CircularProgress />
                        {/* <h4>Cargando metodo de pago...</h4> */}
                    </Box>
                }

            </div>





            {(!paymentInfo) ? <div id='btn-supp-ctn'>
                {(!loading) ? <button className='btn-supp' onClick={handlePreferences}>Contribuir</button> : <button className='btn-supp btn-supp-load'>
                    <p>Cargando...</p>

                </button>}

            </div> :
                <div id='btn-supp-ctn'>
                    <a className='btn-supp btn-supp-mp' href={`${paymentInfo?.pago_link}`}>Pagar en Mercado Pago</a>
                </div>}


            <div id='supp-final-ctn'>
                <table>
                    <thead>

                        <tr>
                            <th>Somos transparentes</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td><span>Mercado Pago:</span><span>${fee.toFixed(2)} MXN</span></td>
                        </tr>
                        <tr>
                            <td><span>contribución  recibida:</span> <span>${((precio * support) - fee).toFixed(2)} MXN</span></td>
                        </tr>
                        <tr>
                            <td><span>un taquito:</span> <span>0 $ MXN</span></td>
                        </tr>
                        <tr>
                            <td><span>Usted paga: </span> <span>${(precio * support).toFixed(2)} MXN</span></td>
                        </tr>

                    </tbody>
                </table>
                <p>*Los valores mostrados en la tabla son estimados en base a los <a href='https://www.mercadopago.com.mx/ayuda/costo-recibir-pagos_683'>costos declarados por la plataforma de pago en sus plataformas web.</a></p>

            </div>


        </div>
    )
}



