import { useState, createContext, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from './UserContext';


export const MercadoPagoContext = createContext();


const MercadoPagoProvider = ({ children }) => {

    const { search } = useLocation();
    const navigate = useNavigate();
    const { GetUserInfo } = useContext(UserContext)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    const MpOauth = async (code) => {

        const url = await fetch(`${process.env.REACT_APP_BACKURL}/mercadopago/auth/token`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": `${process.env.REACT_APP_CLIENTURL}`,
            },
            body: JSON.stringify({ code: code })
        })

            .then(result => result.json())

            // .then(result => console.log(result))
            .catch(error => console.log('error', error));
        await console.log('url', url)
        await navigate(`${url.redirect}`)
        await GetUserInfo()

    }

    const DeleteMpOauth = async () => {

        const url = await fetch(`${process.env.REACT_APP_BACKURL}/mercadopago/auth/eliminar-permisos`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": `${process.env.REACT_APP_CLIENTURL}`,
            },

        })
            .then(result => result.json())
            .catch(error => console.log('error', error));
        await navigate(`${url.redirect}`);
        await GetUserInfo();
    }

    return (
        <MercadoPagoContext.Provider
            value={{
                MpOauth: MpOauth,
                DeleteMpOauth: DeleteMpOauth

            }}
        >
            {children}
        </MercadoPagoContext.Provider>
    );
}
export default MercadoPagoProvider;


