import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SlSocialInstagram, SlSocialLinkedin } from 'react-icons/sl'
import { AiOutlineCopyright } from 'react-icons/ai'
import { GiTacos } from 'react-icons/gi'
import saluda from '../Assets/saluda-azul.webp'
import telefono from '../Assets/telefono.webp'
import TaquitosConjunto from '../Assets/taquitos-conjunto.webp'
import TaquitosConjuntoMob from '../Assets/taquitos-conjunto-mob.webp'
import WhiteCards from "./secondary/WhiteCards";
import "../styles/Intro.scss";


export default function Intro() {

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 700;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const conjunto = (width < breakpoint) ? TaquitosConjuntoMob : TaquitosConjunto;


  const [taquitoLink, setTaquitoLink] = useState(undefined)

  const TaquitoLinkHandle = () => {

    sessionStorage.setItem('taquitoLink', taquitoLink)
  }

  const setTaquitoLinkHandle = (value) => {
    const newValue = value.replace(/[^a-zA-Z0-9-]+/g, "-");
    setTaquitoLink(newValue)
  }

  return (
    <>
      <div id='intro-ctn'>
        <section className='first-section wrapper-ctn wrapper-class'>
          <div className="wrapper wrapper-class">
            <div className="titleCtn">
              <h1 className="title">
                ¡Recibe contribuciones {" "}

                <span className="titleSp">haciendo lo que amas!</span>



              </h1>
              <div className="conjuntos">
                { }
                <img src={conjunto} alt='Imagen de muchos personajes taquitos' />
              </div>
              <p className="comparte">
                <span>Todos tenemos una causa, una idea, una pasión... Una historia para contar.</span><br />
                <span >Empieza a recibir contribuciones. Aumenta tus ingresos presentando tu historia.</span>
                <br />Comparte tus logros, crea comunidad y recibe contribuciones.
                <span style={{ marginTop: '15px',display:'block' }}><i>Todo desde tu unica y atractiva pagina de Un Taquito.</i></span>
              </p>
            </div>
            <div className="input">
              <div className="input-mob">
                <span>untaquito.io/</span>
                <input type="text" placeholder="TuNombre" maxLength="30" value={taquitoLink} onChange={(e) => setTaquitoLinkHandle(e.target.value)} />
              </div>
              <button className="button" onClick={TaquitoLinkHandle}><Link to="/signup">Crea tu taquito</Link></button>
            </div>
            <div className="down-input">
              <span>
                <p>Es más fácil de lo que piensas.</p>
                <p>Es gratis y toma menos de un minuto.</p>
              </span>
            </div>
          </div>
        </section>
        <section className="explicaciones wrapper-ctn wrapper-class">
          <div className="wrapper wrapper-class">
            <div className="cobramos-ctn">
              <img src={saluda} alt="Logo de taquito saludando" loading="lazy" />
              <h1 className="title">¡Cobramos 0% de <br />lo que recibes!</h1>
            </div>
            <div className='grid-exp wrapper-class'>
              {/* <div className="tarjetas-grid">
                <span > */}
              <WhiteCards>
                <h2>Sabemos lo que se siente</h2>
                <p>Queremos que lleves tus ideas al máximo, por eso te brindamos un espacio para que recibas apoyo y no te cobramos nada.</p>
              </WhiteCards>
              {/* </span>
              </div> */}
              <WhiteCards>
                <h2>Queremos ser transparentes</h2>
                <p>Quizás notes que no recibes el 100% de lo que te aportan. Las plataformas de pago cobran un porcentaje por cada transacción. Un Taquito no cobra comisión.</p>
              </WhiteCards>
            </div>
          </div>
        </section>


        <section className='Second-section wrapper-ctn wrapper-class'>
          <div className="wrapper">
            <div className="titleCtn">
              <h1 className="title">
                ¿Quieres contribuir <br /> a una causa?
              </h1>
            </div>
            <div className='grid-exp'>



              <span className='img-ctn'>
                <img src={telefono} alt="ilustracion de telefono con pop-ups explicativos sobre el proceso" loading="lazy" />
              </span>



              <ul id="pasos-list">
                <li><ul>
                  <li className="pasos">Paso 1</li>
                  <li className="pasos-exp">Accede al perfil al que deseas apoyar.</li>
                </ul>
                </li>
                <li>
                  <ul>
                    <li className="pasos">Paso 2</li>
                    <li className="pasos-exp">Selecciona la cantidad de taquitos que deseas contribuir.</li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li className="pasos">Paso 3</li>
                    <li className="pasos-exp">Puedes dejar un mensaje de apoyo o agradecimiento a la persona que estás ayudando.</li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li className="pasos">Paso 4</li>
                    <li className="pasos-exp">Haz click en el botón "contribuir" para iniciar el proceso de contribución.</li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li className="pasos">Paso 5</li>
                    <li className="pasos-exp">Sigue las instrucciones en la plataforma de pago para completar tu contribución.</li>
                  </ul>
                </li>
              </ul>

            </div>
          </div>
        </section>
      </div>
      <footer>
        <div className='grid-footer'>
          <span >
            <h1 className="logoWhite" alt='logo'> un taquito </h1>
            <div id="avisos-preguntas">
              <Link to='/FAQ'>preguntas frecuentes </Link>
              <Link to='/politica-privacidad'>Aviso de privacidad</Link>
              <label>hola@untaquito.io</label>
              <p> <span><AiOutlineCopyright /> un taquito.</span><GiTacos /> Preferentemente de birria... </p>
            </div>
          </span>
          <span>

          </span>
          <span id="redes-ctn">
            <div className="logoWhite">Siguenos!</div>
            <div id="redes">
              {/* <a href='https://twitter.com'><SlSocialTwitter /></a> */}
              <a href='https://www.linkedin.com/company/un-taquito' aria-label="Visita nuestro LinkedIn"><SlSocialLinkedin /></a>
              <a href='https://www.instagram.com/untaquito.app/' aria-label="Visita nuestro Instagram" ><SlSocialInstagram /></a>

            </div>

          </span>
        </div>
      </footer>

    </>
  );
}
