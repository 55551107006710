import { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { UserContext } from '../../Context/UserContext'

const steps = [
    {
        label: 'Elige una foto de perfil.',
        description: ``,
    },
    {
        label: 'Crea tu link de taquito para compartirlo con tu comunidad, amigos y familiares.',
        description:
            '',
    },
    {
        label: 'Cuenta tu historia.',
        description: ``,
    },
    {
        label: 'Conéctate con un medio de pago.',
        description: ``,
    },
    {
        label: '¡Listo! Tu historia ya se podrá ver en el explorador.',
        description: ``,
    },
];

export default function VerticalLinearStepper() {
    const { UserInformation } = useContext(UserContext)

    const [activeStep, setActiveStep] = useState(3);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        const hasPayment = UserInformation && UserInformation.mp_active;
        const hasLink = UserInformation && UserInformation.taquitoLink;
        const hasProfilePhoto = UserInformation && UserInformation.profilePhoto;
        const hasStory = UserInformation && UserInformation.historia;

        setActiveStep(
            hasProfilePhoto && hasLink && hasStory && hasPayment ? 5 :
                hasProfilePhoto && hasLink && hasStory ? 3 :
                    hasProfilePhoto && hasLink ? 2 :
                        hasProfilePhoto ? 1 :
                            0 // Estado inicial
        );

    }, [UserInformation]);


    return (
        <Box sx={{ maxWidth: 400 }} className='lineStepper'>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                index === 3 ? (
                                    <Typography variant="caption">¡Estás cerca de publicar tu historia!</Typography>
                                ) : null
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (

                <Typography>¡Comparte tu perfil y haz crecer tu comunidad! </Typography>


            )}
        </Box>
    );
}