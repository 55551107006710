import { useRef, useState, forwardRef, useContext } from 'react';
import { Link } from "react-router-dom";
import { SlSocialTwitter, SlSocialInstagram, SlDocs } from 'react-icons/sl'
import { ExplorerContext } from '../../Context/ExplorerContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import ImageComponent from '../Explorador/Share'
import "../../styles/ModalShare.scss"
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({ img, userName, taquitoLink }) {
    const inputRef = useRef(null);
    const [openSnack, setOpenSnack] = useState(false);
    const [open, setOpen] = useState(false);
    const [showImageComponent, setShowImageComponent] = useState(false);
    const { GetInstagramImg } = useContext(ExplorerContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleCopy = () => {
        const input = inputRef.current;
        input.select();
        document.execCommand('copy');
        setOpenSnack(true);
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };
    const shareUrl = () => {
        const canonicalElement = document.querySelector("link[rel='canonical']");
        // const url = canonicalElement ? canonicalElement.href : window.location.href;
        const url = window.location.href;
        const text = "¡Chéca esta causa y échale la mano en su aventura!";
        const hashtags = 'ApoyandoAndo,UnTaquito'
        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${url}&hashtags=${encodeURIComponent(hashtags)}`;
        window.open(tweetUrl, '_blank');
    }
    const ShareInstagram = () => {
        setShowImageComponent(true);
        GetInstagramImg(img, userName, taquitoLink);
        handleClose();

    }
    return (
        <div >
            <TopBar handleClickOpen={handleClickOpen} />
            <Dialog
                
                id='pre-share-ctn'
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{ zIndex: 9999}}
            >
                <DialogTitle>{"¡Comparte esta increíble historia!🚀"}</DialogTitle>
                <DialogContent >
                    <Box
                        sx={{
                            width: 500,
                            maxWidth: '100%',
                        }}

                    >
                        <div id='url-input-ctn'>

                            <input
                                ref={inputRef}
                                readOnly
                                value={window.location.href}
                            />
                            <span>
                                <SlDocs onClick={handleCopy} />
                            </span>
                        </div>
                        <Snackbar
                            sx={{ textAlign: 'center' }}
                            autoHideDuration={3000}
                            message={"¡Copiado! 😄"}
                            open={openSnack}
                            onClose={handleCloseSnack} />

                    </Box>
                    <DialogContentText id="alert-dialog-slide-description">
                        {/* <button onClick={() => window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`, '_blank')}><SlSocialTwitter />Twitter</button> */}
                        <button onClick={shareUrl}><SlSocialTwitter />Twitter</button>
                        <button onClick={ShareInstagram}><SlSocialInstagram />Instagram</button>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
            {showImageComponent && <ImageComponent open={showImageComponent} setOpen={setShowImageComponent} />}
        </div>
    );
}

const TopBar = ({ handleClickOpen }) => {
    return (
        <div id="perfil-exp-nav">
            <Link to="/"> <h1 className="logo">un taquito</h1></Link>
            <div>
                <button className="pill pill-first" disabled>Seguir </button>
                <button className="pill pill-second" ><IosShareOutlinedIcon sx={{ fontSize: 13, }} onClick={handleClickOpen} /></button>
            </div>
        </div>
    )
}