
import {useEffect,useContext} from 'react';
import { Outlet } from "react-router-dom"
import { BrowserView, MobileView, isBrowser, isTablet, isMobile } from 'react-device-detect';
import { UserContext } from '../Context/UserContext';
import NavBar from "./NavBar"
import NavBarMobile from "./NavBarMobile"


export default function Main() {
    const { UserInformation } = useContext(UserContext)

    useEffect(() => {
        return
      }, [UserInformation])
   
    const Nav = () => {
        if (isMobile && !isTablet) {
            return (

                <MobileView>
                    <NavBarMobile />
                </MobileView>
            )
        } else if (isTablet || isBrowser) {
            return (<BrowserView>
                <NavBar />
            </BrowserView>)
        }
    }
    return (
        <>
            <Nav/>

            <Outlet />
        </>
    )
}