import { Link } from "react-router-dom"
import img1 from '../../Assets/saltarin-blanco.png'
import img2 from '../../Assets/skater-blanco.png'
import img3 from '../../Assets/guitarra-blanco.png'
import "../../styles/Cards.scss";

export default function Cards({ userData }) {

  const { displayName, historia, category, profilePhoto, taquitoLink, firstName } = userData;
  const defaultImages = [img1, img2, img3];
  const randomImage = defaultImages[Math.floor(Math.random() * 3)];

  const src = (!profilePhoto && `${!process.env.REACT_APP_imgBACKURL}`) ? randomImage : `${process.env.REACT_APP_imgBACKURL}/${profilePhoto}`;
  
  return (
    <div className="card" >
      <Link underline="none" to={`/${taquitoLink}`}>

        <div className="img-ctn">
          <img src={src} alt={`Imagen de perfil de ${firstName} para la categoria ${category}`} />
        </div>
        <div className="text-ctn">
          {displayName ? <h2>{displayName}</h2> : <h2>{firstName}</h2>}
          {historia ? <p>{historia}</p> : <p>{firstName} aun no cuenta su historia. De igual manera puedes contribuir con un taquito. </p>}
        </div>

        <div className="grey-pill">
          <label>{category}</label>
        </div>
      </Link>

    </div>
  );
}
