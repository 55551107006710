import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from '../Context/UserContext';
import { AiOutlineUser } from "react-icons/ai";
import "../styles/NavBar.scss";

export default function NavBar() {
  const { UserInformation } = useContext(UserContext);

  const navBarStyle = {
    logo: 'logo',
    ulButton: 'ul-Crea',
    liButton: 'crea',
    button: 'btn-grad'
  }

  return (
    <nav>
      <Link to="/"><h1 className={navBarStyle.logo}>un taquito</h1></Link>
      <ul className={navBarStyle.ulButton}>
        <li>
          <Link to="/explore">Explorar</Link>
        </li>
        <li>
          {!!UserInformation ? (
            <span className="bienvenido">Bienvenido!</span>
          ) : (
            <Link to="/logIn">Inicia Sesión</Link>
          )}
        </li>
        <li className={navBarStyle.liButton}>
          <button className={navBarStyle.button}>
            {!!UserInformation ? (
              <Link to={`/users/${UserInformation?._id}`}>
                <AiOutlineUser />
                {UserInformation?.firstName || "Completa perfil"}
              </Link>
            ) : (
              <Link to="/signup">Crea tu taquito</Link>
            )}
          </button>
        </li>
      </ul>
    </nav>
  );
}
