import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { UserContext } from '../../Context/UserContext'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';

const NavItem = ({ to, icon: Icon, primary, onClick, key }) => (
    <Link key={key} to={to} style={{ color: 'black' }} onClick={onClick}>
        <ListItem disablePadding>
            <ListItemButton>
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText primary={primary} />
            </ListItemButton>
        </ListItem>
    </Link>
);

export default function SideNavListInicio({ handleDrawerToggle, UserInformation }) {
    const { Logout } = React.useContext(UserContext)
    const location = useLocation();
    const pathname = location.pathname;
    const id = UserInformation?._id;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const HandleLogout = async () => {
        Logout();
        handleDrawerToggle();
    };

    const links = [
        { key: 1, path: "/", icon: CottageOutlinedIcon, text: "Inicio", authenticated: false, alwaysShow: pathname !== '/', click: handleDrawerToggle },
        { key: 2, path: "/explore", icon: Groups2OutlinedIcon, text: "Descubre", authenticated: false, alwaysShow: pathname !== '/explore', click: handleDrawerToggle },
        { key: 3, path: UserInformation ? `/users/${id}` : "/signup", icon: PersonAddAltOutlinedIcon, text: UserInformation ? "Mi cuenta" : "Crear cuenta", authenticated: false, alwaysShow: isMobile ? pathname !== `/users/${id}` : true, click: handleDrawerToggle },
        { key: 4, path: `/users/${id}/perfil`, icon: PersonSearchOutlinedIcon, text: "Perfil", authenticated: true, alwaysShow: UserInformation != null && (isMobile ? !pathname.includes('perfil') : true), click: handleDrawerToggle },
        { key: 5, path: `/users/${id}/pagos`, icon: SavingsOutlinedIcon, text: "Vincular Pagos", authenticated: true, alwaysShow: UserInformation != null && (isMobile ? !pathname.includes('pagos') : true), click: handleDrawerToggle },
        { key: 6, path: UserInformation ? "/logout" : "/logIn", icon: UserInformation ? LogoutOutlinedIcon : LoginOutlinedIcon, text: UserInformation ? "Cierre sesión" : "Inicio Sesión", click: UserInformation ? HandleLogout : handleDrawerToggle, authenticated: false, alwaysShow: true },
    ];

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', zIndex: 9998 }}>
            <div aria-label="main mailbox folders">
                <List>
                    {links.map(({ path, icon, text, click, alwaysShow, key }) => (
                        alwaysShow && <NavItem to={path} icon={icon} primary={text} onClick={click} key={key} />
                    ))}
                </List>
            </div>
            <Divider />
        </Box>
    );
}
