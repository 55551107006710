import React, { useEffect, useState, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PaymentContext } from "../../Context/PaymentContext";
import Saltarin from '../../Assets/saltarin-azul2.png';
import Supporter from "../User/Supporter";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Fade from "@mui/material/Fade";
import ImageComponent from './Share'
import Modal from '../secondary/Modal'
import FundProgressBar from '../secondary/FundProgressBar'
import "../../styles/ProfileExplores.scss"
// import NavBarUserExplorer from "./NavBarUserExplorer";

// import saltarinBlanco from "../Assets/saltarin-blanco.png";
// C:\Users\Moren\Desktop\UnTaquito\data-warehouse-project\untaquito\src\styles\ProfileExplores.scss
export default function PerfilExplorador() {
    let flag = false;
    const { ChangePreferenceStatus } = useContext(PaymentContext);

    const navigate = useNavigate();
    const location = useLocation();
    const { search } = useLocation();
    const collection_status = new URLSearchParams(search).get("collection_status");
    const preference_id = new URLSearchParams(search).get("preference_id");
    const pathname = location.pathname;
    const firstPart = pathname.split('/')[1];
    const [perfil, setPerfil] = useState(null);
    const [notifications, setNotifications] = useState(true);



    useEffect(() => {

        fetch(`${process.env.REACT_APP_BACKURL}/${firstPart}`)
            .then((res) => (res.status === 400) ? navigate(
                "/explore"
            ) : res.json())

            .then((data) => {
                setPerfil(data.user);
                console.log('!perfil?.mp_active',!perfil?.mp_active)
            }
            );

        setNotifications(true);
        setTimeout(() => {
        }, 5000);



    }, []);


    if (preference_id) {

        ChangePreferenceStatus(preference_id, collection_status, perfil?._id);
    }

    return (
        <section id="perfil-exp">
            {/* <TopBar /> */}
            <Modal img={perfil?.profilePhoto} userName={`${perfil?.firstName} ${perfil?.lastName}`} taquitoLink={perfil?.taquitoLink} />
            <div className="user-banner">
                <ImageComponent />
                <div></div>
                {/* <img src={saltarinBlanco}/> */}
            </div>
            <div className="user-profile-pic">
                {/* <img src={user?.profilePhoto} /> */}
                <img src={`${process.env.REACT_APP_imgBACKURL}/${perfil?.profilePhoto}`} />
            </div>
            <div className="name-ctn">
                <div >
                    <h1>{perfil?.firstName} {perfil?.lastName}</h1>
                </div>
            </div>
            <div id="perfil-exp-grid">
                <div id="perfil-exp-recibido" className="tarjetas">
                    <img src={Saltarin} />
                    x {(perfil?.taquitos_recibidos === null) ? 0 : perfil?.taquitos_recibidos} recibidos
                </div>
                <div id="perfil-exp-apoyo" className={`tarjetas ${!perfil?.mp_active ? 'user-inactive' : ''}`}>
                    {flag ? <FundProgressBar /> : null}
                    <Supporter precio={perfil?.unit_price} id={perfil?._id} />
                </div>
                <div className="tarjetas">
                    <h5>Su historia</h5>
                    <p>{perfil?.historia || <RandomQuote />}
                    </p>
                </div>
                <div className="tarjetas tarj-table">
                    <table>
                        <thead>
                            <tr>
                                <th><h5>Top Contribuciones</h5></th>
                            </tr>
                        </thead>
                        <tbody>
                            {perfil?.top_contributors.map((contributor, index) => (
                                <tr key={contributor._id}>
                                    <td>
                                        {index === 0 ? <div className="perfil-tabla">{contributor._id}</div> : <span className="perfil-tabla">{contributor._id}</span>}
                                        <span className="name-perf-tabla">{contributor.name || 'Contribuidor Enmascarado'}</span>
                                        <span className="recib-perfil-tabla">{contributor.totalQty} taquitos</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
            {(collection_status === 'approved' && notifications) ?
                <Fade
                    in={notifications}
                    timeout={{ enter: 1300, exit: 1300 }}
                    unmountOnExit
                    // timeout={4000}
                    addEndListener={() => {
                        setTimeout(() => {
                            setNotifications(null)
                        }, 3000);

                    }}
                >
                    < Stack className="stack-alert" sx={{ width: '100%' }} spacing={2}>
                        <Alert className='alert' severity="success">
                            <AlertTitle>Aprobada</AlertTitle>
                            Su contribución fue aprobada. <strong>¡Gracias!</strong>
                        </Alert>
                    </Stack>
                </Fade> : null
            }
            {(collection_status === 'rejected' && notifications) ?
                <Fade
                    in={notifications}
                    timeout={{ enter: 1300, exit: 1300 }}
                    unmountOnExit
                    // timeout={4000}
                    addEndListener={() => {
                        setTimeout(() => {
                            setNotifications(null)
                        }, 4500);

                    }}
                >
                    < Stack className="stack-alert" sx={{ width: '100%' }} spacing={2}>
                        <Alert className='alert' severity="success">
                            {/* <AlertTitle>Aprobado</AlertTitle> */}
                            <AlertTitle>Rechazada</AlertTitle>
                            Su contribución fue Rechazada. <strong>Intente nuevamente.</strong>
                        </Alert>
                    </Stack>
                </Fade> : null
            }
        </section >
    );
}

// const TopBar = () => {
//     return (
//         <div id="perfil-exp-nav">
//             <Link to="/"> <h1 className="logo">un taquito</h1></Link>
//             <div>
//                 <button className="pill pill-first" disabled>Seguir </button>
//                 <button className="pill pill-second" ><IosShareOutlinedIcon sx={{ fontSize: 13, }} /></button>
//             </div>
//         </div>
//     )
// }

function RandomQuote() {
    const quotes = [
        "¡Ups! Parece que este usuario está guardando su historia para una sorpresa. ¡Mantente atento!",
        "Este usuario aún no ha saltado al escenario para compartir su historia. ¡Esperamos con ansias ese gran momento!",
        "Todo gran narrador tiene su momento. ¡Este usuario aún no ha revelado su historia, pero estamos seguros de que será épica!",
        "¡El misterio continúa! Este usuario aún no ha compartido su historia, pero sabemos que cuando lo haga, será increíble.",
        "Este usuario está cocinando algo especial. ¡Estamos ansiosos por escuchar su historia cuando esté lista para compartirla!"
    ];

    // Selecciona una frase aleatoria
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

    return (
        <div>
            {randomQuote}
        </div>
    );
}

