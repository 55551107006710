import React, { useContext, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { UserContext } from '../../Context/UserContext';
import { MercadoPagoContext } from "../../Context/MercadoPagoContext";

import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
// import saltarinBlanco from "../Assets/saltarin-blanco.png";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SavingsIcon from '@mui/icons-material/Savings';

import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import "../../styles/UsersAuth.scss";



export default function Pagos({ user }) {
  const { search } = useLocation();

  const { UserInformation } = useContext(UserContext)
  const { DeleteMpOauth } = useContext(MercadoPagoContext)

  //get info from ur
  const id = new URLSearchParams(search).get("id");
  const [anchorEl, setAnchorEl] = useState(null);
  const [NewValue, setNewValue] = useState(undefined);

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleNewValue = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    console.log(onlyNums);
    if (onlyNums <= 500) {
      setNewValue(onlyNums);
    }
  }


  const open = Boolean(anchorEl);

  const APP_ID = process.env.REACT_APP_MERCADOPAGO_APP_ID;
  const REDIRECT_URI = process.env.REACT_APP_MERCADOPAGO_REDIRECT_URI;

  // let RANDOM_ID = Math.random().toString(36).substring(7);
  let RANDOM_ID = UserInformation?._id;
  let OAuth = `https://auth.mercadopago.com.mx/authorization?client_id=${APP_ID}&response_type=code&platform_id=mp&state=${RANDOM_ID}&redirect_uri=${REDIRECT_URI}`

  useEffect(() => {

    console.log(UserInformation)
  }, [UserInformation])


  return (
    <div className="pagos-section">

      <h1 className="title title-others">Pagos</h1>
      <List className="ul-pagos" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <SavingsIcon />

            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Donaciones recibidas" secondary={`Info en desarrollo`} />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AttachMoneyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Valor actual tus Taquitos" secondary={(UserInformation?.unit_price) ? `${UserInformation?.unit_price} MXN` : 'Sin valor aun'} />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <span>MP</span>
              {/* <BeachAccessIcon /> */}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Status de Mercado Pago" secondary={(UserInformation?.mp_active) ?
            <span className="vinc-mp-txt">Vinculado</span>
            : <span className="desvinc-mp-txt">No Vinculado</span>} />



        </ListItem>
      </List>
      <section className="paymentSection">
        <div className="paymentInfo">
          {(UserInformation?.mp_active) ?
            <button className="mpButton" onClick={DeleteMpOauth}>
              <span className="desvinc-mp">Desvincular Mercado Pago</span>
            </button>
            :
            <button className="mpButton"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}>
              <a href={OAuth}><span className="vinc-mp">Vincular Mercado Pago</span></a>
            </button>
          }
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            // open={true}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            // transformOrigin={{
            //   vertical: 'top',
            //   horizontal: 'left',
            // }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 2 }}>Solo para Mercado Pago Mexico.</Typography>
          </Popover>

          <Stack direction="column" spacing={2}>
            <FormControl className="inputValor" sx={{ m: 1 }} variant="filled">
              <InputLabel htmlFor="filled-adornment-amount">Nuevo precio de tu taquito</InputLabel>
              <FilledInput
                id="filled-adornment-amount"
                startAdornment={<InputAdornment position="start">MXN</InputAdornment>}
                step="1"
                value={NewValue || ''}

                onChange={handleNewValue}
              />
            </FormControl>


            <Stack className="inputValor buttonValor">
              {/* <Button variant="contained" disabled={!NewValue} endIcon={<SendIcon />}> */}
              <Button variant="contained" disabled={true} endIcon={<SendIcon />}>

                Modificar Valor
              </Button>
            </Stack>
          </Stack>
          {/* <button>Modificar valor de taquito</button> */}
        </div>
      </section>
      {/* <div role='presentation' className="MuiPopover-root MuiModal-root css-e2zslh-MuiModal-root-MuiPopover-root" id='mouse-over-popover' aria-hidden="true"></div> */}
    </div>
  );
}






// export default function InputAdornments() {
//   const [showPassword, setShowPassword] = React.useState(false);

//   const handleClickShowPassword = () => setShowPassword((show) => !show);

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
//       <div>
//         <TextField
//           label="With normal TextField"
//           id="outlined-start-adornment"
//           sx={{ m: 1, width: '25ch' }}
//           InputProps={{
//             startAdornment: <InputAdornment position="start">kg</InputAdornment>,
//           }}
//         />
//         <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
//           <OutlinedInput
//             id="outlined-adornment-weight"
//             endAdornment={<InputAdornment position="end">kg</InputAdornment>}
//             aria-describedby="outlined-weight-helper-text"
//             inputProps={{
//               'aria-label': 'weight',
//             }}
//           />
//           <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>
//         </FormControl>
//         <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
//           <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
//           <OutlinedInput
//             id="outlined-adornment-password"
//             type={showPassword ? 'text' : 'password'}
//             endAdornment={
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   onClick={handleClickShowPassword}
//                   onMouseDown={handleMouseDownPassword}
//                   edge="end"
//                 >
//                   {showPassword ? <VisibilityOff /> : <Visibility />}
//                 </IconButton>
//               </InputAdornment>
//             }
//             label="Password"
//           />
//         </FormControl>

//       </div>
//       <div>
//         <TextField
//           label="With normal TextField"
//           id="filled-start-adornment"
//           sx={{ m: 1, width: '25ch' }}
//           InputProps={{
//             startAdornment: <InputAdornment position="start">kg</InputAdornment>,
//           }}
//           variant="filled"
//         />
//         <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
//           <FilledInput
//             id="filled-adornment-weight"
//             endAdornment={<InputAdornment position="end">kg</InputAdornment>}
//             aria-describedby="filled-weight-helper-text"
//             inputProps={{
//               'aria-label': 'weight',
//             }}
//           />
//           <FormHelperText id="filled-weight-helper-text">Weight</FormHelperText>
//         </FormControl>
//         <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
//           <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
//           <FilledInput
//             id="filled-adornment-password"
//             type={showPassword ? 'text' : 'password'}
//             endAdornment={
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   onClick={handleClickShowPassword}
//                   onMouseDown={handleMouseDownPassword}
//                   edge="end"
//                 >
//                   {showPassword ? <VisibilityOff /> : <Visibility />}
//                 </IconButton>
//               </InputAdornment>
//             }
//           />
//         </FormControl>
//         <FormControl fullWidth sx={{ m: 1 }} variant="filled">
//           <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
//           <FilledInput
//             id="filled-adornment-amount"
//             startAdornment={<InputAdornment position="start">$</InputAdornment>}
//           />
//         </FormControl>
//       </div>
//       <div>
//         <TextField
//           label="With normal TextField"
//           id="standard-start-adornment"
//           sx={{ m: 1, width: '25ch' }}
//           InputProps={{
//             startAdornment: <InputAdornment position="start">kg</InputAdornment>,
//           }}
//           variant="standard"
//         />
//         <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '25ch' }}>
//           <Input
//             id="standard-adornment-weight"
//             endAdornment={<InputAdornment position="end">kg</InputAdornment>}
//             aria-describedby="standard-weight-helper-text"
//             inputProps={{
//               'aria-label': 'weight',
//             }}
//           />
//           <FormHelperText id="standard-weight-helper-text">Weight</FormHelperText>
//         </FormControl>
//         <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
//           <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
//           <Input
//             id="standard-adornment-password"
//             type={showPassword ? 'text' : 'password'}
//             endAdornment={
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   onClick={handleClickShowPassword}
//                   onMouseDown={handleMouseDownPassword}
//                 >
//                   {showPassword ? <VisibilityOff /> : <Visibility />}
//                 </IconButton>
//               </InputAdornment>
//             }
//           />
//         </FormControl>
//         <FormControl fullWidth sx={{ m: 1 }} variant="standard">
//           <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
//           <Input
//             id="standard-adornment-amount"
//             startAdornment={<InputAdornment position="start">$</InputAdornment>}
//           />
//         </FormControl>
//       </div>
//     </Box>
//   );
// }