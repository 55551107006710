import { useState, useEffect, useContext } from 'react';
import { ExplorerContext } from '../Context/ExplorerContext';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Cards from "./secondary/Cards";
import src from "../Assets/contribui-azul.png";
import CategoriesCar from './secondary/CategoriesCar.js'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import noResults from '../Assets/sad-blanco.png'
import "../styles/Explorer.scss";



export default function Explorer() {

  const [page, setPage] = useState(1);
  const [category, setCategory] = useState(null);
  const { GetExplorer, GetExploreCategory, dataExplore } = useContext(ExplorerContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let qty = isMobile ? 3 : 8;

  useEffect(() => {
    GetExplorer()
  }, [])

  const handlePagination = (event, value) => {
    setPage(value);
    GetExploreCategory(category, page)
  }


  useEffect(() => {
  }, [dataExplore])

  return (
    <div id="explorer-comp">
      <div className="explorer-img-ctn">
        <img src={src} alt='Imagen ilustrativa dice Contribui con un taquito para darle encabezado a la seccion explorador. Tiene una ilustracion de un taquito sonriente saltando.' />
      </div>

      <div id='categories-ctn'>
        <h1 className='title'>Busca historias</h1>

        <CategoriesCar setCategory={setCategory} setPage={setPage} />

      </div>
      {(dataExplore?.users?.length === 0) ?
        <>
          <img src={noResults} className='noResult-img' />
          <h1 className='title noResult'>No hay historias que coincidan con tu busqueda.</h1>
        </>
        : null}

      <div className="users-explorer">
        {(!dataExplore?.users) ?

          Array.from(new Array(qty)).map((user, i) => {
            return (
              <section key={i} style={{ marginTop: '35px' }}>
                <Stack spacing={1} sx={{ marginBottom: '50px' }}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="text" width={230} sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" width={230} sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="rounded" width={230} height={60} />
                </Stack>
              </section>)
          })
          :

          dataExplore?.users.map((user, i) => {
            return <Cards key={i} userData={user} />;
          })}

      </div>
      {(dataExplore?.totalPages) > 1 ?
        <Stack spacing={2} sx={{ width: '50%', margin: ' 5% 25% 0 25%', /*border: '1px solid rgb(265,265,265,0.5)', borderRadius: '25px', background: 'rgb(265,265,265,0.3)'*/ }}>

          <Pagination color='primary' onChange={(event, pageNumber) => handlePagination(event, pageNumber)} count={dataExplore?.totalPages} sx={{ justifyContent: 'center', alignContent: 'center', width: 'auto', border: 'none', padding: '10px', margin: '0' }} />

        </Stack> : null}

    </div>
  );
}




