import { useEffect, useContext } from 'react'
import { Navigate, useRoutes } from 'react-router-dom';

import { UserContext } from './Context/UserContext'

import LogIn from "./components/LogIn";
import Explorer from "./components/Explorer";
import Main from './components/Main';
import Intro from './components/Intro';
import Dashboard from './components/Dashboard';
import Pagos from './components/User/Pagos'
import CompletarPerfil from './components/User/CompletarPerfil'
import PerfilExplorador from "./components/Explorador/PerfilExplorador";
import CallbackMP from './components/User/CallbackMP';
import UserInicio from './components/User/UserInicio';
import ChecaMail from './components/ChecaMail';
// import UserInicio from './components/User/UserInicio';

export default function Router() {
  const { GetUserInfo } = useContext(UserContext);

  useEffect(() => {
    GetUserInfo()
  }, [])
  const routes = useRoutes([

    {
      path: 'login',
      element: <LogIn />,
    },
    {
      path: 'signup',
      element: <LogIn />,
    },
    {
      path: 'logout',
      element: <ChecaMail/>,
    },
    {
      path: 'check-your-inbox',
      element: <ChecaMail/>,
    },
    {
      path: 'completar-perfil/:id',
      element: <CompletarPerfil addedClass={'completarComp'} />,
    },
    {
      path: 'explore',
      element: <Main />,
      children: [
        { element: <Explorer />, index: true },]
    },
    {
      element: <Main />,
      children: [
        { element: <Intro />, index: true },
        { path: '404', element: <h1>No hemos encontrado la pagina</h1> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: 'users/:id',
      element: <Dashboard />,
      children: [
        { path: '', element: <UserInicio/> },
        { path: 'inicio', element: <UserInicio/> },
        { path: 'perfil', element: <CompletarPerfil addedClass={'perfilComp'} /> },
        { path: 'pagos', element: <Pagos /> },]

    },
    {
      path: '/:username',
      element: <PerfilExplorador />,

    },
    {
      path: '/mercadopago/callback',
      element: <CallbackMP/>,

    },
  ]);

  return routes;
}
