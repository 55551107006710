/// we could delete those

import { useEffect, useContext, useState } from "react";
import { BrowserRouter, } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import Router from "./routes";
import UserProvider from '../src/Context/UserContext';
import PaymentProvider from '../src/Context/PaymentContext';
import ExplorerProvider from '../src/Context/ExplorerContext';
import MercadoPagoProvider from "./Context/MercadoPagoContext";

import "./App.scss";

function App() {


  return (
    <div className="App">

      <BrowserRouter>
        <ExplorerProvider>
          <UserProvider>
            <MercadoPagoProvider>
              <PaymentProvider>
                <Analytics mode={'production'} />

                <Router />

              </PaymentProvider>
            </MercadoPagoProvider>
          </UserProvider>
        </ExplorerProvider>
      </BrowserRouter>

    </div>
  );
}

export default App;
