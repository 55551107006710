
import * as React from 'react';
import { Link, useLocation } from "react-router-dom";
import { Box, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, alpha } from '@mui/material/styles';

import Taquito from '../../Assets/facha-azul.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import SideNavListInicio from '../secondary/SideNavListInicio';

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));




export default function Desplegable({ open, handleClick, setDesp, UserInformation }) {

    const theme = useTheme();
    const location = useLocation();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
        handleClick(!open);
    };
    const currentPath = location.pathname;

    return (
        <div style={styles.trying} onClick={open ? handleClick : setDesp(null)}>

            <Drawer
                open={isMobile ? open : true}
                onClose={handleDrawerToggle}
                variant={isMobile ? "temporary" : "permanent"}
                PaperProps={{
                    sx: {
                        width: isMobile ? '100%' : '25%',
                        bgcolor: 'background.default',
                        borderRightStyle: 'dashed',
                        zIndex: 1000000
                    },
                }}
            >

                <LogoBox />
                <UserInformationBox userInformation={UserInformation} />
                <SideNavListInicio handleDrawerToggle={handleDrawerToggle} UserInformation={UserInformation} />
                <Box sx={{ flexGrow: 1 }} />
                <TaquitoImage src={Taquito} />


            </Drawer>

        </div>
    );
}

const styles = {
    trying: {
        position: 'absolute',
        zIndex: '10000',
    }
}

const LogoBox = ({ handleDrawerToggle }) => (
    <Box sx={{ px: 2.5, py: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h1" className='logo' sx={{ fontSize: '1.9rem' }}>
            un taquito
        </Typography>
        <IconButton onClick={handleDrawerToggle}>
            <ArrowBackIcon />
        </IconButton>
    </Box>
);

//Componente para la información del usuario
const UserInformationBox = ({ userInformation }) => (
    <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
            <StyledAccount>
                {userInformation ? (
                    <>
                        <Avatar src={userInformation.profilePhoto} alt={`${userInformation.firstName} - foto de perfil`} />
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                {userInformation.firstName} {userInformation.lastName}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {userInformation.email}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <>
                        <Avatar /> {/* Podrías usar un avatar genérico */}
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                Taquito enmascarado
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Inicia sesión para ver tu perfil
                            </Typography>
                        </Box>
                    </>
                )}
            </StyledAccount>
        </Link>
    </Box>
);


//Componente para la imagen de Taquito
const TaquitoImage = ({ src }) => (
    <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative', }}>
            <Box
                component="img"
                src={src}
                sx={{ width: 200, position: 'absolute', top: -130, zIndex: -1000 }}
            />
        </Stack>
    </Box>
);