// import ChecaMailImg from "../Assets/checaMail.png";
// import "../styles/UserInicio.scss";
import { useLocation } from 'react-router-dom';
import checaImg from '../Assets/checa_emailAlta.png';

export default function ChecaMail() {
    let location = useLocation();

    return (
        <div className='profile-section wrapper-ctn wrapper-class wrapper-ctn-checa'>
            <section className="title-ctn" >
                {location.pathname === "/logout" ? <h1>Hasta luego</h1> : <img src={checaImg} alt="checa" />}
            </section >
        </div>
    )
}