import { createContext, useState } from 'react';
import { useNavigate } from "react-router-dom";


export const PaymentContext = createContext();


const PaymentProvider = ({ children }) => {
    const [paymentInfo, setPaymentInfo] = useState(false);
    // const navigate = useNavigate();


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    const MercadoPagoPreferences = (body) => {

        fetch(`${process.env.REACT_APP_BACKURL}/mercadopago/auth/preferences`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        })
            .then((response) =>
                response.json())
            .then((data) => {
                console.log(data.body)
                setPaymentInfo(data.body)

            })
            .catch((error) => { console.log(error) })
    }

    const ChangePreferenceStatus = (preference_id, collection_status, id) => {

        const body = JSON.stringify({ preference_id, collection_status, id });


        fetch(`${process.env.REACT_APP_BACKURL}/mercadopago/auth/preferences/status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        })
            .then((response) =>
                response.json())
            .then((data) => {
                console.log(data.body)
                setPaymentInfo(data.body)

            })
            .catch((error) => { console.log(error) })


    }


    return (
        <PaymentContext.Provider
            value={{
                MercadoPagoPreferences: MercadoPagoPreferences,
                ChangePreferenceStatus: ChangePreferenceStatus,
                paymentInfo: paymentInfo


            }}
        >
            {children}
        </PaymentContext.Provider>
    );
}
export default PaymentProvider;

