

export default function WhiteCards({children}){

    return(
        <div className="tarjetas-grid">
                <span >
                {children}
                </span>
              </div>
    )
}