
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import VerticalLinearStepper from "../secondary/VerticalStepper"
import "../../styles/UserInicio.scss";

export default function UserInicio() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div className='profile-section wrapper-ctn wrapper-class userInicio-ctn'>
            <section className="title-ctn" >

                <h1 className="title">¡Bienvenido!</h1>

            </section >

            <VerticalLinearStepper />
            <ul className="vertical-ul">
                <li className='first-Vli'>contactos</li>
                <li className='first-Vli'>hola@untaquito.io</li>
                {isMobile ? null :
                    <>
                        <li>moreno@untaquito.io</li>
                        <li>luli@untaquito.io</li>
                    </>}
            </ul>
        </div>
    )
}

// const styles = {

//     div: {
//         height: '90vh',
//         background: 'rgb(249,249,249)',
//         background: 'linear-gradient(140deg, rgba(249,249,249,1) 0%, rgba(232,238,249,1) 40%, rgba(183,232,247,1) 53%, rgba(134,226,245,1) 73%, rgba(0,209,238,1) 88%, rgba(0,180,242,1) 94%)',
       
//         },

// }