import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { MercadoPagoContext } from "../../Context/MercadoPagoContext";


export default function CallbackMP() {


    const { search } = useLocation();
    const code = new URLSearchParams(search).get("code");


    const { MpOauth } = useContext(MercadoPagoContext)

    useEffect(() => {
        MpOauth(code)

    }, [])

    return (
        <>
            <Backdrop
                sx={{  color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
                

            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}


