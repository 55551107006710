import React, { useContext, forwardRef, useEffect, useState } from 'react';
import { ExplorerContext } from '../../Context/ExplorerContext';
import { SlCloudDownload,SlClose } from "react-icons/sl";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import "../../styles/ModalShare.scss"

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageComponent({ open, setOpen }) {
    const { imageSrc } = useContext(ExplorerContext);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogActions>
                <button className='svg-share' download><a href={imageSrc} download><SlCloudDownload/></a></button>
                    <button className='svg-share' onClick={handleClose}><SlClose/></button>
                </DialogActions>
                <DialogContentText id="alert-dialog-slide-description">
                    {(!imageSrc) ? <div>Loading...</div> :
                        <img id='generated-img' src={imageSrc} alt="Generated" />
                    }
                </DialogContentText>

            </Dialog>
        </div>
    );
}
