import { useState, useContext } from 'react';
import { ExplorerContext } from '../../Context/ExplorerContext';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';



export default function CategoriesCar({ setCategory, setPage }) {

  const { GetExplorer, GetExploreCategory } = useContext(ExplorerContext);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCategory = (category_id) => {

    if (category_id !== 'Todos') {

      GetExploreCategory(category_id, 1);
    } else { GetExplorer() }
    setCategory(category_id);
    setPage(1);
  }

  return (
    <Box
      sx={{
        flexGrow: 2,
        maxWidth: { xs: 850, sm: 850, md: 850, lg: 850, xl: 850 },
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        // bgcolor: 'background.paper',

      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <Tab label="Todos" onClick={() => handleCategory('Todos')} />
        <Tab label="ONG's" onClick={() => handleCategory('ONGs')} />
        <Tab label="Musicos" onClick={() => handleCategory('Musicos')} />
        <Tab label="Escritores" onClick={() => handleCategory('Escritores')} />
        <Tab label="Podcasters" onClick={() => handleCategory('Podcasters')} />
        <Tab label="Fotografos" onClick={() => handleCategory('Fotografos')} />
        <Tab label="Cracks" onClick={() => handleCategory('Cracks')} />
        <Tab label="Creadores Contenido" onClick={() => handleCategory('Creadores Contenido')} />
        <Tab label="Otras historias" onClick={() => handleCategory('Otras historias')} />

      </Tabs>
    </Box>
  );
}