
import { useContext, useEffect, useState } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom"
import { UserContext } from '../Context/UserContext';
import { Box, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import Taquito from '../Assets/facha-azul.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBarMobile from "./NavBarMobile";



import "../styles/Variables.scss";
import SideNavListInicio from "./secondary/SideNavListInicio";

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const DrawerDiv = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  marginLeft: 0,
  [theme.breakpoints.up('md')]: {
    width: '75%',
    marginLeft: '25%',
  },
}));

export default function Dashboard() {

  const { GetUserInfo, UserInformation } = useContext(UserContext)
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;
  const secondPathPart = pathname.split('/')[2];

  useEffect(() => {
    GetUserInfo(secondPathPart)
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  if (UserInformation?.active_profile === false) {
    navigate(`/completar-perfil/${UserInformation._id}?nombre=${UserInformation.firstName || ''}&apellido=${UserInformation.lastName || ''}`)
  } else {
    return (
      <>

        {isMobile && (
          <NavBarMobile/>
        )}

        <Drawer
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          variant={isMobile ? "temporary" : "permanent"}
          PaperProps={{
            sx: {
              width: isMobile ? '100%' : '25%',
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
              zIndex: 9998
            },
          }}
        >

          <LogoBox />
          <UserInformationBox userInformation={UserInformation} />
          <SideNavListInicio UserInformation={UserInformation}  handleDrawerToggle={handleDrawerToggle}/>
          <Box sx={{ flexGrow: 1 }} />
          <TaquitoImage src={Taquito} />


        </Drawer>

        <DrawerDiv>
          <Outlet />
        </DrawerDiv>

      </>
    )
  }
}




const LogoBox = () => (
  <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', justifyContent: 'center' }}>
    <Link to="/">
      <Typography variant="h1" className='logo' sx={{ fontSize: '1.5rem' }}>
        un taquito
      </Typography>
    </Link>
  </Box>
);

//Componente para la información del usuario
const UserInformationBox = ({ userInformation }) => (
  <Box sx={{ mb: 5, mx: 2.5 }}>
    <Link underline="none">
      <StyledAccount>
        <Avatar src={userInformation?.profilePhoto} alt={`${userInformation?.firstName} - foto de perfil`} />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {userInformation?.firstName} {userInformation?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {userInformation?.email}
          </Typography>
        </Box>
      </StyledAccount>
    </Link>
  </Box>
);

//Componente para la imagen de Taquito
const TaquitoImage = ({ src }) => (
  <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
    <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative', }}>
      <Box
        component="img"
        src={src}
        sx={{ width: 200, position: 'absolute', top: -130, zIndex: -1000 }}
      />
    </Stack>
  </Box>
);

