import React, { useContext, useState } from "react";
import { UserContext } from '../Context/UserContext';
import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import saluda from '../Assets/saluda-azul.webp'
import Desplegable from "./NavBar/Desplegable";




export default function NavBarMobile() {
  const { UserInformation } = useContext(UserContext)

  /////////////////////// Logica de Desplegable ///////////////////////
  const [Desp, setDesp] = useState(null);
  const open = Boolean(Desp);
  const DespHandleClick = (event) => {
    !open ?
      setDesp(event.currentTarget) :
      setDesp(null)
  };

  return (


    <Box sx={{ flexGrow: 1, position: 'absolute', width: '100%' }}>

      <AppBar position="static" id='mob-navbar'>

        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to="/" className='logoWhite'>
            <img src={saluda} alt='logo de taquito saludando' /></Link>
          <div style={styles.accountCircleCtn} >

            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 0, color: '#002B5A', paddingBottom: 0 }}
              onClick={DespHandleClick}

            >
              <MenuIcon />

              <Desplegable open={open} anchorEl={Desp} handleClick={DespHandleClick} setDesp={setDesp} UserInformation={UserInformation} />

            </IconButton>


          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}


const styles = {
  accountCircleCtn: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center'
  }
}