import { createContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


export const UserContext = createContext();


const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    const [UserInformation, setUserInformation] = useState(null);

    useEffect(() => {

    }, [UserInformation]);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    function GetUserInfo(id) {
        console.log('Se llamo!')
        fetch(`${process.env.REACT_APP_BACKURL}/auth/check_auth`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": `${process.env.REACT_APP_CLIENTURL}`,
                "Referer": window.location.href
            }
        })
            .then((response) =>
                response.json())

            .then((data) => {
                console.log(data)
                if (data.infoUser) {
                    setUserInformation(data.infoUser);
                } else {
                    setUserInformation(null);
                }
            });

    }
    const ModifyProfile = (formData) => {
        fetch(`${process.env.REACT_APP_BACKURL}/user/actualizar-perfil`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    // Manejo de errores basado en el estado de la respuesta
                    if (response.status === 304) {
                        throw new Error('No se han realizado modificaciones en el perfil');
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                }
                return response.text(); // Intentamos leer la respuesta como texto
            })
            .then((text) => {
                if (!text) {
                    // Si el texto está vacío, lanzamos un error específico
                    throw new Error('Respuesta vacía del servidor');
                }
                try {
                    // Intentamos parsear el texto como JSON
                    const data = JSON.parse(text);
                    navigate(`/users/${data.id}`);
                } catch (error) {
                    // Si hay un error al parsear el JSON, lo manejamos
                    throw new Error('Error al parsear JSON: ' + error.message);
                }
            })
            .catch((error) => {
                // Manejo de errores con más detalles
                console.log('Error en ModifyProfile:', error);
            });
    }

    const Logout = async () => {
        console.log('loggin out')
        try {
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Access-Control-Allow-Credentials", true);
            myHeaders.append("Access-Control-Allow-Origin", `${process.env.REACT_APP_CLIENTURL}`);
            const response = await fetch(`${process.env.REACT_APP_BACKURL}/logout`, {
                method: 'GET',
                credentials: 'include',

            });


            if (response.ok) {
                console.log('Logout successful');
                window.location.href = process.env.REACT_APP_CLIENT_URL || '/'; // Redirige después del logout
            } else {
                console.error('Logout failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    }
    return (
        <UserContext.Provider
            value={{
                GetUserInfo: GetUserInfo,
                ModifyProfile: ModifyProfile,
                Logout: Logout,
                UserInformation: UserInformation,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}
export default UserProvider;

