import { createContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


export const ExplorerContext = createContext();


const ExplorerProvider = ({ children }) => {

    const [dataExplore, setDataExplore] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    function GetExplorer() {
        fetch(`${process.env.REACT_APP_BACKURL}/explore`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": `${process.env.REACT_APP_CLIENTURL}`,
            }
        })
            .then((res) => res.json())
            .then((data) => {

                setDataExplore(data);
            })

    }

    function GetExploreCategory(category, page) {

        fetch(`${process.env.REACT_APP_BACKURL}/explore/${category}?page=${page}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": `${process.env.REACT_APP_CLIENTURL}`,
            }
        })
            .then((res) => res.json())
            .then((data) => {

                setDataExplore(data);
                console.log(data)
            })

    }
    function GetInstagramImg(img, userName, taquitoLink) {
        
        fetch(`${process.env.REACT_APP_BACKURL}/ig-share-img?img=${img}&userName=${userName}&taquitoLink=${taquitoLink}`)
            .then(res => res.blob())
            .then(blob => {
                const imageSrc = URL.createObjectURL(blob);
                setImageSrc(imageSrc);
            })
            .catch(err => console.error(err));
    }

    return (
        <ExplorerContext.Provider
            value={{
                GetExplorer: GetExplorer,
                GetExploreCategory: GetExploreCategory,
                GetInstagramImg: GetInstagramImg,
                dataExplore: dataExplore,
                imageSrc: imageSrc

            }}
        >
            {children}
        </ExplorerContext.Provider>
    );
}
export default ExplorerProvider;

