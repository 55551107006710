import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../Context/UserContext';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import "../../styles/CompletarPerfil.scss";



export default function CompletarPerfil({ addedClass }) {
    const { search } = useLocation();
    const { ModifyProfile, UserInformation } = useContext(UserContext)


    const location = useLocation();
    const pathname = location.pathname;
    const secondPathPart = pathname.split('/')[2];

    const [image, setImage] = useState(null);
    const [imageSRC, setImageSRC] = useState(UserInformation?.profilePhoto || null);
    const [nombre, setNombre] = useState(new URLSearchParams(search).get("nombre") || UserInformation?.firstName || null);
    const [apellido, setApellido] = useState(new URLSearchParams(search).get("apellido") || UserInformation?.lastName || null);
    const [taquitoLink, setTaqutioLink] = useState(UserInformation?.taquitoLink || null);
    const [historia, setHistoria] = useState(UserInformation?.historia || null);
    const [instagram, setInstagram] = useState(UserInformation?.instagram || null);
    const [twitter, setTwitter] = useState(UserInformation?.twitter || null);
    const [availability, setAvailability] = useState(true);


    const FillTaquitoLink = () => {
        if (sessionStorage.getItem('taquitoLink')) {
            setTaqutioLink(sessionStorage.getItem('taquitoLink'));
            sessionStorage.removeItem('taquitoLink')
        }
    }

    const handleOnChange = useCallback((setter) => (e) => setter(e.target.value), []);

    const profile = useMemo(() => ({
        firstName: nombre,
        lastName: apellido,
        taquitoLink: taquitoLink,
        historia: historia,
        instagram: instagram,
        twitter: twitter,
        oldProfilePhoto: UserInformation?.profilePhoto
    }), [nombre, apellido, taquitoLink, historia, instagram, twitter, UserInformation?.profilePhoto]);


    useEffect(() => {
        FillTaquitoLink();
    }, []);


    const checkAvailability = async (link) => {
        const response = await fetch(`${process.env.REACT_APP_BACKURL}/check-availability`, {
            method: 'POST',
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "access-control-allow-credentials": true,
            },
            body: JSON.stringify({ taquitoLink: link })
        });

        const data = await response.json();
        
        return data.available;
    };

    const handleTaquitoLink = async (e) => {
        const newValue = e.target.value.replace(/[^a-zA-Z0-9-]+/g, "-");
        setTaqutioLink(newValue);

        const isAvailable = await checkAvailability(newValue);
        setAvailability(isAvailable);
    };
    const handlePhotoChange = e => {
        setImage(e.target.files[0]);
        setImageSRC(URL.createObjectURL(e.target.files[0]));
    };

    const handleUpload = (e) => {
        const formData = new FormData();
        formData.append('image', image);
        formData.append('profile', JSON.stringify(profile));
       
        ModifyProfile(formData)
    }

    useEffect(() => {
    }, [nombre, apellido]);


    return (
        <section className={`profile-section wrapper-ctn wrapper-class ${addedClass} wrapper-class-c-perfil`}>
            <div className='wrapper perfil-wrapper'>
                          <h1 alt='titulo de compartir perfil' className='title title-others'>Completa tu perfil</h1>
                <div>
                    <div className='circle-photo'>
                        {/* {(imageSRC) ? <img src={`${process.env.REACT_APP_BACKURL}/${imageSRC}`} /> : <CameraAltRoundedIcon />} */}
                        {image ? <img src={URL.createObjectURL(image)} /> : (imageSRC ? <img src={`${process.env.REACT_APP_imgBACKURL}/${imageSRC}`} /> : <CameraAltRoundedIcon />)}


                        <input className={(imageSRC) ? 'input-imagen' : ''} alt="input para cargar foto" type="file" accept="image/jpeg,image/jpg,image/png" name="profile_picture" onChange={handlePhotoChange} />
                    </div>
                    <div id='agrega-foto'>Agrega tu foto</div>
                </div>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    className='box'
                >

                    <div className='inputs-wrapper'>
                        <TextField
                            id="filled-multiline-flexible"
                            label="Nombre"
                            multiline
                            required={true}
                            maxRows={1}
                            maxLength={2}
                            variant="filled"
                            className='inputs-profile'
                            defaultValue={nombre}
                            onChange={handleOnChange(setNombre)}
                        />
                        <TextField
                            id="filled-multiline-flexible"
                            label="Apellido"
                            multiline
                            required={true}
                            maxRows={1}
                            variant="filled"
                            className='inputs-profile'
                            defaultValue={apellido}
                            value={apellido}
                            onChange={handleOnChange(setApellido)}
                        />
                        <TextField
                            label="Tu link de taquito"
                            id="outlined-start-adornment"
                            variant="filled"
                            className='inputs-profile'
                            sx={{ m: 1, width: '25ch' }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">untaquito.io/</InputAdornment>,
                            }}
                            defaultValue={taquitoLink}
                            value={taquitoLink}
                            helperText={(availability) ? '' : 'El link no esta disponible'}
                            error={!availability}
                            onChange={(e) => { handleTaquitoLink(e); }}
                        />

                        <TextField
                            id="filled-multiline-static"
                            label="Cuenta tu historia"
                            placeholder="Hola! Me cree mi perfil en un taquito. Ahora puedes apoyar mi musica comprandome un taquito!"
                            multiline
                            rows={7}
                            // defaultValue="Default Value"
                            variant="filled"
                            className='inputs-profile'
                            defaultValue={historia}
                            value={historia}
                            onChange={handleOnChange(setHistoria)}
                        />

                        <TextField
                            label="Instagram"
                            id="outlined-start-adornment"
                            variant="filled"
                            className='inputs-profile'
                            sx={{ m: 1, width: '25ch' }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">instagram.com/</InputAdornment>,
                            }}
                            defaultValue={instagram}
                            value={instagram}
                            onChange={handleOnChange(setInstagram)}
                        />
                        <TextField
                            label="Twitter"
                            id="outlined-start-adornment"
                            variant="filled"
                            className='inputs-profile'
                            sx={{ m: 1, width: '25ch' }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">twitter.com/</InputAdornment>,
                            }}

                            defaultValue={twitter}
                            value={twitter}
                            onChange={handleOnChange(setTwitter)}
                        />

                    </div>

                </Box>

                <Box className='box' style={styles.box}>
                <button style={styles.button} disabled={!nombre || !apellido} type="submit" onClick={(e) => handleUpload(e)} className='button button-profile' >{(addedClass === 'completarComp') ? 'Crear perfil' : 'Modificar perfil'}</button>
                </Box>
                <p id='aviso-perfil'>Tu perfil no aparecera en el explorador hasta no tener tu cuenta de pago asociada a <span className='logo'>un taquito</span>.<br />
                    Puedes hacerlo desde tu perfil en la seccion <i>vincular pagos</i>.
                </p>

            </div>
        </section>
    )
}

const styles = {
    box:{
        display:'flex',
        justifyContent:'center'
    },
    button:{
        width:'80%',
       
    }
}

